<section id="footer">
  <div class="grid mb-4 footer-container">
    <article>
      <div class="footer_profile_image text-left">
        <img src="/assets/images/logo.png" />
      </div>
      <p id="description">{{ "footer.footer_desc" | translate }}</p>
    </article>
    <article id="links">
      <!-- <a class="" routerLink="/" style="--order: 0">{{"Home" | translate}}</a>
            <a class="" routerLink="about" style="--order: 0.25">{{"About" | translate}}</a>
            <a class="" routerLink="events" style="--order: 0.50">{{"Events" | translate}}</a> -->
      <!-- <a class="" routerLink="/privacy-policy" style="--order: 0.75">{{"privacy_policy.Privacy Policy" | translate}}</a> -->
      <div class="poweredBy">
        <p>Powered by</p>
        <div class="images-container">
          <div class="img-yf">
            <img src="/assets/images/yunus-france.png" alt="yunus france" />
          </div>
          <div class="img-yyv">
            <img src="/assets/images/yy-ventures.png" alt="yy ventures" />
          </div>
          <div class="img-yyv">
            <img src="/assets/images/yc-logo.png" alt="yunus centre" />
          </div>
        </div>
      </div>
      <div class="supportBy">
        <p>Supported by</p>
        <div class="images-container">
          <div class="img-yyf">
            <img src="/assets/images/yy-foundation.png" alt="yy foundation" />
          </div>
          <div class="img-np">
            <img
              src="/assets/images/national-postcode.png"
              alt="national postcode"
            />
          </div>
        </div>
      </div>
    </article>
    <article class="address_section">
      <div id="contact">
        <a
          href="https://www.facebook.com/3zclub"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="fa fa-facebook"></i
        ></a>
        <a
          href="https://www.instagram.com/3zeroclub/"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="fa fa-instagram"></i
        ></a>
        <a
          href="https://twitter.com/3zeroClub"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="/assets/images/twitter-logo.png" alt=""
        /></a>
        <a
          href="https://www.linkedin.com/company/3zero-club"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="fa fa-linkedin"></i
        ></a>
        <a
          href="https://www.youtube.com/@3zeroclub494"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="fa-brands fa-youtube"></i
        ></a>
      </div>
      <p [innerHTML]="'footer.address_line_one' | translate"></p>
      <!-- <p>{{ "footer.address_line_two" | translate }}</p> -->
      <!-- <p>{{ "footer.address_line_three" | translate }}</p> -->
      <p
        id="email"
        style="padding-top: 0px !important; margin-top: 0px !important"
      >
        {{ "email" | translate }}
      </p>
      <!-- <p class="mt-2">{{"support_one" | translate}}</p><p>{{"support_two" | translate}}</p> -->
    </article>
  </div>
  <!-- <article style="--order: 2"><p>{{"copyright" | translate}}</p></article> -->
  <!-- <article></article> -->
  <!-- <article><p>{{"support_one" | translate}}</p><p>{{"support_two" | translate}}</p></article> -->
  <div class="links-and-copy-right">
    <div class="links-container">
      <a class="" routerLink="/register" style="--order: 0.75">{{
        "Register" | translate
      }}</a>
      <!-- <a class="" routerLink="/global-center" style="--order: 1">{{"3Z Global Centre" | translate}}</a> -->
      <a class="" routerLink="/resources" style="--order: 1.25">{{
        "Resources" | translate
      }}</a>
      <a class="" routerLink="/application-faq" style="--order: 1">{{
        "FAQ" | translate
      }}</a>
      <a class="" routerLink="/newsletters" style="--order: 1">{{
        "Newsletters" | translate
      }}</a>
      <a class="" routerLink="/contact-us" style="--order: 1.5">{{
        "write_to_us" | translate
      }}</a>
      
    </div>
    <div class="divider"></div>
    <p class="copyright">{{ "copyright" | translate }}</p>
  </div>
</section>
