import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./main/errors/not-found/not-found.component";
import { MainComponent } from "./main/main.component";

export const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/background/background.module").then(
            (m) => m.BackgroundModule
          ),
      },
      {
        path: "home",
        loadChildren: () =>
          import("./main/background/background.module").then(
            (m) => m.BackgroundModule
          ),
      },
      {
        path: "about",
        loadChildren: () =>
          import("./main/about/about.module").then((m) => m.AboutModule),
      },
      {
        path: "events",
        loadChildren: () =>
          import("./main/events/events.module").then((m) => m.EventsModule),
      },
      {
        path: "events/e4e",
        loadChildren: () =>
          import("./main/event-details/event-details.module").then(
            (m) => m.EventDetailsModule
          ),
      },
      {
        path: "events/e4e-2.0",
        loadChildren: () =>
          import("./main/e4e-2.0/e4e-2.0.module").then((m) => m.E4E2Module),
      },
      {
        path: "events/imld",
        loadChildren: () =>
          import("./main/imld/imld.module").then((m) => m.IMLDModule),
      },
      {
        path: "events/3ZeroClubConvention",
        loadChildren: () =>
          import(
            "./main/three-zero-club-convention-event-details/three-zero-club-convention-event-details.module"
          ).then((m) => m.ThreeZeroClubConventionEventDetailsModule),
      },
      {
        path: "events/3ZeroClubConvention2024",
        loadChildren: () =>
          import(
            "./main/3ZERO-Club-Convention/three-zero-club-convention-event-details.module"
          ).then((m) => m.ThreeZeroClubConventionEventDetailsModule),
      },
      {
        path: "events/advancing-towards-a-three-zero-future",
        loadChildren: () =>
          import(
            "./main/advancing-towards-a-three-zero-future/advancing-towards-a-three-zero-future.module"
          ).then((m) => m.ThreeZeroClubConventionEventDetailsModule),
      },
      // {
      //   path: "blogs/3ZeroClubConvention",
      //   loadChildren: () =>
      //     import(
      //       "./main/3ZERO-Club-Convention/three-zero-club-convention-event-details.module"
      //     ).then((m) => m.ThreeZeroClubConventionEventDetailsModule),
      // },
      {
        path: "bulletin",
        loadChildren: () =>
          import("./main/bulletin/bulletin.module").then(
            (m) => m.BulletinModule
          ),
      },
      {
        path: "bulletin-details/:id",
        loadChildren: () =>
          import("./main/bulletin-details/bulletin-details.module").then(
            (m) => m.BulletinDetailsModule
          ),
      },
      {
        path: "global-center",
        loadChildren: () =>
          import("./main/global-center/global-center.module").then(
            (m) => m.GlobalCenterModule
          ),
      },
      {
        path: "register",
        loadChildren: () =>
          import("./main/register/register.module").then(
            (m) => m.RegisterModule
          ),
      },
      {
        path: "login",
        loadChildren: () =>
          import("./main/login/login.module").then((m) => m.LoginModule),
      },
      {
        path: "club-profile",
        loadChildren: () =>
          import("./main/club-profile/club-profile.module").then(
            (m) => m.ClubProfileModule
          ),
      },
      {
        path: "edit-my-profile",
        loadChildren: () =>
          import("./main/edit-profile/edit-profile.module").then(
            (m) => m.EditProfileModule
          ),
      },
      {
        path: "my-profile",
        loadChildren: () =>
          import("./main/profile/profile.module").then((m) => m.ProfileModule),
      },
      {
        path: "bulletin/create",
        loadChildren: () =>
          import("./main/bulletin/bulletin-admin/bulletin.module").then(
            (m) => m.BulletinModule
          ),
      },
      {
        path: "bulletin/edit/:id",
        loadChildren: () =>
          import("./main/bulletin/edit-bulletin/bulletin-edit.module").then(
            (m) => m.BulletinEditModule
          ),
      },
      {
        path: "faq",
        loadChildren: () =>
          import("./main/faq/faq.module").then((m) => m.FaqModule),
      },
      {
        path: "resources",
        loadChildren: () =>
          import("./main/resources/resources.module").then(
            (m) => m.ResourcesModule
          ),
      },
      {
        path: "newsletters",
        loadChildren: () =>
          import("./main/newsletters/newsletters.module").then(
            (m) => m.NewsletterModule
          ),
      },
      {
        path: "contact-us",
        loadChildren: () =>
          import("./main/contact-us/contact-us.module").then(
            (m) => m.ContactUsModule
          ),
      },
      {
        path: "application-faq",
        loadChildren: () =>
          import("./main/open-faq/open-faq.module").then(
            (m) => m.OpenFaqModule
          ),
      },
      {
        path: "privacy-policy",
        loadChildren: () =>
          import("./main/privacy-policy/privacy-policy.module").then(
            (m) => m.PrivacyPolicyModule
          ),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("./main/profile/reset-password/reset-password.module").then(
            (m) => m.ResetPasswordModule
          ),
      },
      {
        path: "bulletin-author-profile/:id",
        loadChildren: () =>
          import(
            "./main/profile/bulletine-profile-author/bulletine-profile-author.module"
          ).then((m) => m.BulletineProfileAuthorModule),
      },
      {
        path: "story",
        loadChildren: () =>
          import("./main/story/story.module").then((m) => m.StoryModule),
      },
      {
        path: "story-details/:id",
        // loadChildren:()=>import('./main/story/story-details/story-details.module').then(m=>m.StoryDetailsModule)
        // loadChildren: () => import('./main/bulletin-details/bulletin-details.module').then(m => m.BulletinDetailsModule)
        loadChildren: () =>
          import("./main/story/story-details/story-details.module").then(
            (m) => m.StoryDetailsModule
          ),
      },
    ],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: "legacy",
      useHash: false,
      scrollPositionRestoration: "enabled",
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
