import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TokenStorageService } from "../../../../app/main/login/token-storage.service";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { MenuService } from "../menu/menu.service";

declare var jQuery: any;
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
  animations: [
    trigger("showInfo", [
      state("1", style({ transform: "rotate(180deg)" })),
      state("0", style({ transform: "rotate(0deg)" })),
      transition("1 => 0", animate("400ms")),
      transition("0 => 1", animate("400ms")),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  public showHorizontalMenu: boolean = true;
  public showInfoContent: boolean = false;
  public settings: Settings;
  public menuItems: Array<any>;
  public fontSize = 12;
  isLoggedIn: any;
  user;
  public homeActiveClass: boolean = true;
  public aboutActiveClass: boolean = false;
  public eventsActiveClass: boolean = false;
  public threeZeroClubConventionActiveClass: boolean = false;
  public bulletinActiveClass: boolean = false;
  public globalCenterActiveClass: boolean = false;
  public registerCenterActiveClass: boolean = false;
  public loginCenterActiveClass: boolean = false;

  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    private token: TokenStorageService,
    public translate: TranslateService
  ) {
    this.settings = this.appSettings.settings;
    this.menuItems = this.menuService.getHorizontalMenuItems();
    translate.addLangs(["en", "fr", "pt", "np", "es"]);
    // translate.setDefaultLang('en');
    if (localStorage.getItem("locale")) {
      const browserLang = localStorage.getItem("locale");
      translate.use(browserLang.match(/en|fr|pt/) ? browserLang : "en");
    } else {
      localStorage.setItem("locale", "en");
      translate.setDefaultLang("en");
    }
  }

  ngOnInit() {
    if (this.token.getToken()) {
      this.isLoggedIn = true;
      // this.roles = this.tokenStorage.getUser().roles;
      this.user = this.token.getUser();
    }
    if (window.innerWidth <= 768) this.showHorizontalMenu = false;
  }
  logout(): void {
    this.token.signOut();
    // window.location.reload();
    window.location.replace("/");
  }

  public closeSubMenus() {
    let menu = document.querySelector("#menu0");
    if (menu) {
      for (let i = 0; i < menu.children.length; i++) {
        let child = menu.children[i].children[1];
        if (child) {
          if (child.classList.contains("show")) {
            child.classList.remove("show");
            menu.children[i].children[0].classList.add("collapsed");
          }
        }
      }
    }
  }

  @HostListener("window:resize")
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.showHorizontalMenu = false;
    } else {
      this.showHorizontalMenu = true;
    }
  }

  public increaseFontSize() {
    for (let i = 12; i < 30; i += 2) {
      let fontSize = $(".font-size-" + i).css("font-size");
      fontSize = parseInt(fontSize);
      fontSize = fontSize < 24 ? fontSize + 1 + "px" : fontSize + "px";
      $(".font-size-" + i).css("fontSize", fontSize);
    }
  }

  public decreaseFontSize() {
    for (let i = 12; i < 30; i += 2) {
      let fontSize = $(".font-size-" + i).css("font-size");
      fontSize = parseInt(fontSize);
      fontSize = fontSize > 10 ? fontSize - 1 + "px" : fontSize + "px";
      $(".font-size-" + i).css("fontSize", fontSize);
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  clickMenueEvent(menu: string = "") {
    this.homeActiveClass = true;
    this.aboutActiveClass = false;
    this.eventsActiveClass = false;
    this.threeZeroClubConventionActiveClass = false;
    this.bulletinActiveClass = false;
    this.globalCenterActiveClass = false;
    this.registerCenterActiveClass = false;
    this.loginCenterActiveClass = false;

    if (menu == "about") {
      this.aboutActiveClass = true;
      this.homeActiveClass = false;
    }
    if (menu == "event") {
      this.eventsActiveClass = true;
      this.homeActiveClass = false;
    }
    if (menu == "3ZeroClubConvention") {
      this.threeZeroClubConventionActiveClass = true;
      this.homeActiveClass = false;
    }
    if (menu == "bulletin") {
      this.bulletinActiveClass = true;
      this.homeActiveClass = false;
    }
    if (menu == "golbal-center") {
      this.globalCenterActiveClass = true;
      this.homeActiveClass = false;
    }
    if (menu == "register") {
      this.registerCenterActiveClass = true;
      this.homeActiveClass = false;
    }
    if (menu == "login") {
      this.loginCenterActiveClass = true;
      this.homeActiveClass = false;
    }

    $("#collapsibleNavbar").removeClass("show");
  }
}
