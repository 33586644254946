import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    httpOptions = {
        // headers: new HttpHeaders({'content-type': 'multipart/form-data'})
        };
    private baseUrl = environment.apiUrl;


    constructor(private http: HttpClient) {}

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        if(error.status==404 || error.status==401){
            // console.log(error);
            // return false;
            window.localStorage.clear();
            window.location.replace('/login');
        }
       //window.alert(errorMessage);
        return throwError(errorMessage);
    }

    getCountryList(){
        return this.http.get(this.baseUrl + 'club/club_api/geo').pipe(catchError(this.handleError));
    }
    getBackGround() {
        return this.http.get(this.baseUrl + 'background').pipe(catchError(this.handleError));
    }

    getStateList(country){
        return this.http.get(this.baseUrl + 'club/club_api/geo?type=state&parent_id='+country).pipe(catchError(this.handleError));
    }

    getCityList(state){
        return this.http.get(this.baseUrl + 'club/club_api/geo?type=city&parent_id='+state).pipe(catchError(this.handleError));
    }

    saveProfile(formData){
        return this.http.post(this.baseUrl + 'club/club_api/club_member', formData, this.httpOptions).pipe(catchError(this.handleError));
    }
    updateProfile(formData,access_token){
        return this.http.post(this.baseUrl + 'club/club_api/club_member?access_key='+access_token, formData, this.httpOptions).pipe(catchError(this.handleError));
       
    }
    getFocusAgrea(){
        return this.http.get(this.baseUrl + 'club/club_api/focus_areas').pipe(catchError(this.handleError));
    }

    getMemberInfo(access_key){
        const params = new HttpParams().append("access_key", access_key);
        return this.http.get(this.baseUrl + 'club/club_api/club_member',{params}).pipe(catchError(this.handleError));
        // return [];
    }
    getAuthorInfo(access_key, member_key){
        const params = new HttpParams().append("access_key", access_key);
        return this.http.get(this.baseUrl + 'club/club_api/club_member/'+member_key,{params}).pipe(catchError(this.handleError));
        // return [];
    }
    resetPassword(formData){
        return this.http.post(this.baseUrl + 'ciaur/auth_api/change_password', formData, this.httpOptions).pipe(catchError(this.handleError));
    }

}
