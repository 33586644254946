import { Injectable } from '@angular/core';
import {ProfileService} from '../../../app/main/profile/profile.service';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const access_token='';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor(private profileService:ProfileService) {

   }

  signOut(): void {
    window.localStorage.clear();
    // window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }
  

  public getToken(): string | null {
    if(window.localStorage.getItem(TOKEN_KEY)){
          // this.profileService.getMemberInfo(window.localStorage.getItem(TOKEN_KEY)).subscribe((data) => { });
          return window.localStorage.getItem(TOKEN_KEY);
    }    
    return '';
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }
}