import { AgmCoreModule } from '@agm/core';
import { LocationStrategy, PathLocationStrategy,HashLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HighchartsChartModule } from 'highcharts-angular';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';
import { MainComponent } from './main/main.component';
import { BlankComponent } from './pages/blank/blank.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { SearchComponent } from './pages/search/search.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { HeaderTopComponent } from './theme/components/header-top/header-top.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { SideChatComponent } from './theme/components/side-chat/side-chat.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { PipesModule } from './theme/pipes/pipes.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        PerfectScrollbarModule,
        NgbModule,
        MultiselectDropdownModule,
        HighchartsChartModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        ToastrModule.forRoot(),
        PipesModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AppComponent,
        PagesComponent,
        MainComponent,
        HeaderComponent,
        FooterComponent,
        HeaderTopComponent,
        SidebarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        BreadcrumbComponent,
        BackTopComponent,
        FullScreenComponent,
        ApplicationsComponent,
        MessagesComponent,
        UserMenuComponent,
        FlagsMenuComponent,
        SideChatComponent,
        FavoritesComponent,
        BlankComponent,
        SearchComponent,
        NotFoundComponent
    ],
    providers: [
        AppSettings,
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        // {provide: LocationStrategy, useClass: HashLocationStrategy}
        {provide: LocationStrategy, useClass: PathLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
