<header class="women-entrepreneur app-header">
  <article class="container-fluid">
    <nav class="navbar navbar-expand-md bg-white navbar-light">
      <a routerLink="/" class="navbar-brand">
        <img src="assets/images/logo.png" alt="img" class="img-fluid logo" />
      </a>
      <!-- <span class="navbar-brand noBorder pt-2">
                <a routerLink="/">
                <img src="/assets/img/background/LOGO.svg" alt="img" class="img-fluid"/>
                </a>
            </span> -->

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse all-menu" id="collapsibleNavbar">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              [ngClass]="homeActiveClass ? 'active' : ''"
              [routerLink]="['/']"
              routerLinkActive=""
              (click)="clickMenueEvent('home')"
            >
              <span>{{ "Home" | translate }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [ngClass]="aboutActiveClass ? 'active' : ''"
              routerLink="/about"
              routerLinkActive=""
              (click)="clickMenueEvent('about')"
            >
              <span>{{ "About" | translate }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [ngClass]="eventsActiveClass ? 'active' : ''"
              routerLink="/events"
              routerLinkActive=""
              (click)="clickMenueEvent('event')"
            >
              <span>{{ "Events" | translate }}</span>
            </a>
          </li>
          <ng-template *ngIf="isLoggedIn; then bulletinContent"> </ng-template>
          <ng-template #bulletinContent>
            <li class="nav-item">
              <a
                [ngClass]="bulletinActiveClass ? 'active' : ''"
                class="nav-link"
                routerLink="/bulletin"
                routerLinkActive=""
                (click)="clickMenueEvent('bulletin')"
              >
                <span>{{ "Bulletin" | translate }}</span>
              </a>
            </li>
          </ng-template>

          <li class="nav-item">
            <a
              [ngClass]="globalCenterActiveClass ? 'active' : ''"
              class="nav-link"
              routerLink="/global-center"
              routerLinkActive=""
              (click)="clickMenueEvent('golbal-center')"
              ><span>{{ "3Z Global Centre" | translate }}</span></a
            >
          </li>

          <!-- <li class="nav-item">
            <a
              [ngClass]="threeZeroClubConventionActiveClass ? 'active' : ''"
              class="nav-link"
              routerLink="/events/3ZeroClubConvention"
              routerLinkActive=""
              (click)="clickMenueEvent('3ZeroClubConvention')"
              ><span>{{ "3ZERO Club Convention" | translate }}</span></a
            >
          </li> -->

          <ng-template *ngIf="isLoggedIn; then loginContent; else loggedOut">
          </ng-template>
          <ng-template #loggedOut>
            <li class="nav-item">
              <a
                [ngClass]="loginCenterActiveClass ? 'active' : ''"
                class="nav-link"
                routerLink="/login"
                routerLinkActive=""
                (click)="clickMenueEvent('login')"
                ><span>{{ "Login" | translate }}</span></a
              >
            </li>
            <li class="nav-item register">
              <a
                [ngClass]="registerCenterActiveClass ? 'active' : ''"
                class="nav-link"
                routerLink="/register"
                routerLinkActive=""
                (click)="clickMenueEvent('register')"
                ><span>{{ "Register" | translate }}</span></a
              >
            </li>
          </ng-template>
          <ng-template #loginContent>
            <li class="nav-item user-profile">
              <div class="dropdown d-inline-block mt-2">
                <a
                  id="user-menu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  class="dropdown-toggle no-caret pl-2 pr-2"
                >
                  <!-- <i aria-hidden="true" class="fa fa-user"></i> -->
                  <img
                    src="/assets/images/profile_icon.svg"
                    alt=""
                    class="profile-dropdown-icon"
                  />
                  <!-- {{ user["first_name"].charAt(0) }} -->
                </a>
                <div
                  aria-labelledby="user-menu"
                  class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow"
                >
                  <div class="user-menu">
                    <div class="user-info text-center p-3">
                      <!-- <img src="assets/images/user.jpg" alt="user-img" class="user-img rounded-circle"> -->

                      <div class="background-ripple">
                        <div class="profile_image">
                          <img
                            class="user-img rounded-circle"
                            src="{{ user['profile_image'] }}"
                            onerror="this.src='/assets/images/user.jpg'"
                            alt=""
                          />
                        </div>
                      </div>
                      <!-- <p class="mt-1 mb-0">
                        {{ user["first_name"] }}
                      </p> -->
                    </div>
                    <a
                      class="nav-link border-bottom"
                      (click)="clickMenueEvent()"
                      routerLink="/club-profile"
                      routerLinkActive=""
                    >
                      <!-- <i class="fa fa-users mr-2"></i> -->
                      {{ "Club_Profile" | translate }}</a
                    >
                    <a
                      class="nav-link border-bottom"
                      (click)="clickMenueEvent()"
                      routerLink="/my-profile"
                      routerLinkActive=""
                    >
                      <!-- <i class="fa fa-user mr-2"></i> -->
                      {{ "My_Profile" | translate }}</a
                    >
                    <a
                      class="nav-link border-bottom"
                      (click)="clickMenueEvent()"
                      routerLink="/reset-password"
                      routerLinkActive=""
                    >
                      <!-- <i class="fa fa-lock mr-2"></i> -->
                      {{ "Reset Password" | translate }}</a
                    >
                    <!-- <a routerlink="settings" class="dropdown-item" href="/settings"><i class="fa fa-cog mr-2"></i>Settings</a><a routerlink="/" class="dropdown-item" href="/"><i class="fa fa-lock mr-2"></i>Lock screen</a> -->
                    <a
                      class="nav-link"
                      (click)="clickMenueEvent()"
                      (click)="logout()"
                    >
                      <!-- <i class="fa fa-power-off mr-2"></i> -->
                      {{ "Log Out" | translate }}</a
                    >
                  </div>
                </div>
              </div>
            </li>
          </ng-template>
          <li class="nav-item lang_item">
            <span class="form-inline switch_lang_item">
              <select
                class="switch_lang form-control rtm"
                #selectedLang
                (change)="switchLang(selectedLang.value)"
              >
                <option
                  *ngFor="let language of translate.getLangs()"
                  [value]="language"
                  [selected]="language === translate.currentLang"
                >
                  <span class="language_code">
                    {{ language.toUpperCase() }}</span
                  >
                </option>
              </select>
            </span>
          </li>
        </ul>
      </div>
    </nav>
  </article>
</header>
<script src="https://3zero.club/scripts/dom_utils.js" defer=""></script>
<link
  rel="stylesheet"
  href="https://3zero.club/public/fonts/fontawesome/css/brands.css"
/>
<link rel="stylesheet" href="/assets/css/index.css" />
<style>
  .nav-item .fa-user {
    font-size: 25px;
  }
  .dropdown-menu .user-menu {
    /* width: 240px;
    padding: 0; */
    width: 238px;
    padding: 0;
    height: auto;
    top: 64px;
    bottom: 0px;
    height: auto;
    /* position: fixed; */
  }
  .app.combined .dropdown.show .dropdown-menu {
    min-height: 382px;
    height: auto;
    /* overflow: auto; */
    width: 240px;
    display: inline-table;
  }
  .dropdown-menu .user-menu .user-img {
    width: 100px;
    display: inline-block;
  }
  .dropdown-menu a:hover {
    background: red;
  }
  nav .navbar-nav a:last-child.active_link,
  nav .navbar-nav a:last-child:hover {
    background: none !important;
    color: #ef9740 !important;
  }
  .app.combined .dropdown-menu .user-menu a {
    text-align: center;
  }
  .app.combined .dropdown-menu .user-menu .nav-link.border-bottom {
    border-bottom: 2px solid #72dad8 !important;
    margin: 0 15%;
    padding: 6px 0px;
  }

  .app.combined .dropdown-menu .user-menu li a:hover {
    background: none;
    color: #45a4a3;
  }

  .app.combined .dropdown-menu .user-menu .user-info {
    /* background-color: #45a4a3;
    color: #ffffff; */
    color: #ffffff;
    background-image: linear-gradient(180deg, #45a4a3 50%, #fff 50%);
    height: 190px;
    width: 100%;
    margin-bottom: 0px;
  }
  .app.combined .dropdown-menu .user-menu .user-info p {
    color: #fff;
  }

  nav .navbar-nav a:active,
  nav .navbar-nav a:hover {
    color: #ffffff !important;
  }
  a {
    cursor: pointer;
  }
  ol a:hover {
    color: #46a4a3;
  }
</style>
